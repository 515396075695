import { SectionOuter } from '@/shared/ui/section-outer';
import { Box, Grid, Typography, Container, Stack } from '@mui/material';
import Image from 'next/image';

import { LOAN_CALCULATOR_ITEM_NODE_ID } from '@/utils';
import { FirstLoanCalculatorWithSlider } from './calculator-item-with-slider';
import { NotStyledParser } from '@/shared/ui';
import { THomeBlockFirstLoanCalculator } from '@/shared/api/homepage-api';

export type FirstLoanCalculatorOuterProps = Pick<
  THomeBlockFirstLoanCalculator,
  | 'title'
  | 'subtitle'
  | 'title_mobile'
  | 'subtitle_mobile'
  | 'calculator'
  | 'imageDesktop'
  | 'imageMobile'
  | 'backgroundDesktop'
  | 'miniBlockAbove'
  | 'backgroundColor'
>;

export const FirstLoanCalculatorOuter = (
  props: FirstLoanCalculatorOuterProps,
) => {
  const {
    calculator,
    subtitle,
    title,
    title_mobile,
    subtitle_mobile,
    imageMobile,
    imageDesktop,
    backgroundDesktop,
    miniBlockAbove,
    backgroundColor,
  } = props;

  const style = {
    contentWrapper: {
      minHeight: undefined,
    },
    topContainer: {
      textAlign: 'left',
      backgroundColor: backgroundColor ?? 'primary.95',
    },
    topContainerInner: {
      maxWidth: undefined,
      width: '50%',
    },
    imageMobileContainer: {
      background: {
        xs: 'none',
        md: `url(${backgroundDesktop?.data?.attributes?.url}) no-repeat right center`,
      },
    },
    imageBackgroundContainer: {
      backgroundColor: 'transparent',
    },
    imageMobile: {
      display: 'none',
    },
  } as const;

  const content = (
    <Box
      sx={{
        display: 'flex',
        minHeight: style.contentWrapper.minHeight,
        background: style.imageMobileContainer.background,
        backgroundSize: {
          md: 'cover',
        },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          alignSelf="flex-end"
          sx={style.imageBackgroundContainer}
        >
          <Box
            display={style.imageMobile.display}
            justifyContent="center"
            px={3}
          >
            <Image
              src={imageMobile?.data?.attributes?.url}
              alt={imageMobile?.data?.attributes?.alternativeText || ''}
              height={imageMobile?.data?.attributes?.height}
              width={imageMobile?.data?.attributes?.width}
              style={{
                height: 'auto',
              }}
              priority
            />
          </Box>
          <Box
            display={{
              xs: 'none',
              md: 'flex',
            }}
            justifyContent="center"
            px={4}
          >
            <Image
              src={imageDesktop?.data?.attributes?.url}
              alt={imageDesktop?.data?.attributes?.alternativeText || ''}
              height={412}
              width={
                (412 / imageDesktop?.data?.attributes?.height) *
                imageDesktop?.data?.attributes?.width
              }
            />
          </Box>
        </Grid>

        {miniBlockAbove?.data ? (
          <Grid item xs={12} display={{ md: 'none' }}>
            <Box display="flex" justifyContent="center" pt={2} pb={4}>
              <Image
                src={miniBlockAbove.data.attributes.url}
                alt={miniBlockAbove.data.attributes.alternativeText || ''}
                width={miniBlockAbove.data.attributes.width}
                height={miniBlockAbove.data.attributes.height}
                style={{ maxWidth: 300, height: 'auto' }}
              />
            </Box>
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          md={4}
          order={{
            xs: 2,
            md: 0,
          }}
          alignSelf="center"
        >
          <Box
            py={{
              xs: 0,
              md: 3.75,
            }}
            sx={{
              backgroundColor: 'transparent',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
                minWidth: {
                  md: 400,
                },
              }}
            >
              <FirstLoanCalculatorWithSlider calculator={calculator} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box id={LOAN_CALCULATOR_ITEM_NODE_ID}>
      <Box display={{ xs: 'block', md: 'none' }}>
        <Container
          sx={{
            overflow: 'hidden',
            mt: {
              sm: -2,
            },
            pt: {
              xs: 2.5,
              md: 3.75,
            },
            ...style.topContainer,
          }}
        >
          <Stack direction="row" alignItems="flex-end">
            <Box
              maxWidth={style.topContainerInner.maxWidth}
              width={style.topContainerInner.width}
              alignSelf="self-start"
              pb={2}
              flex="auto"
            >
              <Typography
                component="div"
                variant="headline5"
                color="neutral.20"
                mb={1}
              >
                <NotStyledParser data={title_mobile} />
              </Typography>

              <Typography component="div" variant="text6" color="neutral.50">
                <NotStyledParser data={subtitle_mobile} />
              </Typography>
            </Box>

            <Image
              src={imageMobile?.data?.attributes?.url}
              alt={imageMobile?.data?.attributes?.alternativeText || ''}
              width={180}
              height={180}
              style={{
                marginRight: -16,
              }}
            />
          </Stack>
        </Container>

        <SectionOuter
          sx={{
            background: 'transparent',
            borderRadius: 0,
            mt: -1.5,
          }}
          disablePaddings
          disableGutters
        >
          {content}
        </SectionOuter>
      </Box>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Container
          sx={{
            my: 3.375,
            textAlign: 'center',
          }}
        >
          <Typography
            component="div"
            variant="headline1"
            color="neutral.20"
            mb={1.25}
          >
            <NotStyledParser data={title} />
          </Typography>
          <Typography component="div" variant="text4" color="neutral.50">
            <NotStyledParser data={subtitle} />
          </Typography>
        </Container>
        <SectionOuter
          sx={{
            borderRadius: 2,
            bgcolor: backgroundColor ?? 'primary.95',
          }}
          disablePaddings
        >
          {content}
        </SectionOuter>

        {miniBlockAbove?.data ? (
          <Box
            display="flex"
            justifyContent="center"
            mt={{
              md: -1,
              lg: -6,
            }}
          >
            <Image
              src={miniBlockAbove.data.attributes.url}
              alt={miniBlockAbove.data.attributes.alternativeText || ''}
              width={miniBlockAbove.data.attributes.width}
              height={miniBlockAbove.data.attributes.height}
              style={{ maxWidth: 500, height: 'auto' }}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
